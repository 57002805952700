/** @jsx jsx */
import { jsx } from "theme-ui"
import CookieConsent from "react-cookie-consent"

export default ({ language }) => (
  <CookieConsent
    disableStyles={true}
    buttonText="Got it"
    buttonStyle={{
      color: "#fff",
      padding: "10px 15px",
      background: language.color_primary,
      margin: "0 20px",
    }}
    style={{
      background: "rgba(0, 0, 0, 0.71)",
      fontFamily: "Avenir Next, sans-serif",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      color: "#fff",
      flexWrap: "wrap",
      position: "fixed",
      bottom: 0,
      padding: 10,
      left: 0,
      width: "100%",
      zIndex: 10,
    }}
  >
    This website uses cookies to enhance the user experience.
  </CookieConsent>
)
