/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"

const adConfig = {
  small: { height: 250, slot: 2179722858 },
  large: { height: 600, slot: 2182101598 },
  responsive: { slot: 2066551521 },
}

export default ({ type }) => {
  useEffect(() => {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return type === "responsive" ? (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-7524880635253719"
      data-ad-slot={adConfig[type].slot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  ) : (
    <ins
      className="adsbygoogle"
      style={{
        display: "inline-block",
        width: "300px",
        height: `${adConfig[type].height}px`,
      }}
      data-ad-client="ca-pub-7524880635253719"
      data-ad-slot={adConfig[type].slot}
    ></ins>
  )
}
