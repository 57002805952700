import { css } from "@emotion/core"

const editDark = "#fc7a7a"
const editMed = "#fac3c3"
const editLight = "#fff7f7"
const editFill = "#f90808"

export default css`
  // html {
  //   scroll-behavior: smooth;
  // }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading {
    font-family: "Avenir Next", "Helvetica", sans-serif;
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 700;
  }

  .naked-btn {
    border: none;
    background: none;
    padding: 0;
  }

  button {
    cursor: pointer;
  }

  thead tr::after {
    content: "",
    position: absolute;
    height: 1px;
    widht: 100%;
    background: #dcdcdc;
    bottom: 0;
    left: 0;
  }

  //  EDIT STYLES

  .edit-mode {
    #primary-sidebar,
    footer,
    .grayscale {
      filter: grayscale(1);
    }

    .edit-row {
      cursor: pointer;
      border-bottom: 1px solid ${editMed};

      &:hover {
        background: rgba(255, 0, 0, 0.1);
        td {
          color: #da2525;
        }
      }
    }

    .naked-btn.active,
    .naked-btn:hover {
      color: #da2525;
      svg {
        fill: ${editFill};
      }
    }

    .edit-verb {
      cursor: pointer;

      h4 {
        color: #da2525;
        border-color: ${editMed};
      }

      &:hover {
        background: rgba(255, 0, 0, 0.1);
        color: #da2525;
      }
    }

    .edit-phrase {
      border-color: ${editMed};
      &:hover {
        cursor: pointer;
        background: rgba(255, 0, 0, 0.1);
        color: #da2525;
      }
    }

    .edit-heading {
      background: ${editDark} !important;
      color: #fff !important;
      border: none !important;
    }

    .edit-theme,
    .edit-theme svg {
      background-color: ${editLight};
      border-color: ${editMed} !important;

      .heading {
        color: #da2525;
      }
    }

    #toggle-edit-mode {
      background-color: rgba(255, 0, 0, 0.1);
      border-color: ${editMed};
      span {
        color: ${editFill};
      }
      svg {
        fill: ${editFill};
        border-color: ${editMed};
      }
    }
  }

  @keyframes fadeInUp {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }
`
