/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { animated, useSpring } from "react-spring"

const Image = animated(Img)

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans = (x, y) => `translate3d(${x / 80}px,${y / 80}px,0) scale(1.2)`

const Background = props => {
  const [spring, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      sx={{
        zIndex: 1,
        "div > img": {
          objectFit: "cover",
          objectPosition: "center",
          fontFamily: "object-fit: cover",
        },
      }}
    >
      <Image
        {...props}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          transform: spring.xy.interpolate(trans),
        }}
      />
    </div>
  )
}

export default Background
