import React from "react"

import {
  VerbIcon,
  PhraseIcon,
  VocabIcon,
  SearchIcon,
  HomeIcon,
} from "../../assets/icons"

// Vocabulary Menu
const extraVocab = () =>
  process.env.GATSBY_LANGUAGE === "French"
    ? { label: "Countries", path: "/vocabulary/countries" }
    : null

export const vocabMenu = [
  { label: "Adjectives", path: "/vocabulary/adjectives", featured: true },
  { label: "Animals", path: "/vocabulary/animals", featured: true },
  { label: "Careers", path: "/vocabulary/careers" },
  { label: "Clothing", path: "/vocabulary/clothing", featured: true },
  extraVocab(),
  { label: "Date & Time", path: "/vocabulary/date", featured: true },
  { label: "Drinks", path: "/vocabulary/drinks" },
  { label: "Food", path: "/vocabulary/food", featured: true },
  { label: "Health", path: "/vocabulary/health" },
  { label: "Household", path: "/vocabulary/household" },
  { label: "Locations", path: "/vocabulary/locations" },
  { label: "Money", path: "/vocabulary/money" },
  { label: "Numbers", path: "/vocabulary/numbers", featured: true },
  { label: "People", path: "/vocabulary/people", featured: true },
  { label: "Prepositions", path: "/vocabulary/prepositions" },
  { label: "Travel", path: "/vocabulary/travel", featured: true },
]

// Phrase Menu

export const phraseMenu = [
  { label: "Airport", path: "/phrasebook/airport" },
  { label: "Date & Time", path: "/phrasebook/date-time" },
  { label: "Dining", path: "/phrasebook/dining", featured: true },
  { label: "Directions", path: "/phrasebook/directions" },
  { label: "Doctor Visit", path: "/phrasebook/doctor" },
  { label: "Emergency", path: "/phrasebook/emergency", featured: true },
  { label: "Flirting", path: "/phrasebook/flirting" },
  { label: "Greetings", path: "/phrasebook/greetings", featured: true },
  { label: "Hotel", path: "/phrasebook/hotel" },
  { label: "Manners", path: "/phrasebook/manners" },
  { label: "Money", path: "/phrasebook/money" },
  {
    label: "Questions & Replies",
    path: "/phrasebook/questions-replies",
    featured: true,
  },
  { label: "Shopping", path: "/phrasebook/shopping" },
  { label: "Transportation", path: "/phrasebook/transportation" },
  { label: "Weather", path: "/phrasebook/weather" },
]

export const navMenu = [
  { label: "Verbs", path: "/verbs", icon: <VerbIcon /> },
  {
    label: "Vocab",
    path: "/vocabulary",
    icon: <VocabIcon />,
    children: vocabMenu,
  },
  {
    label: "Phrases",
    path: "/phrasebook",
    icon: <PhraseIcon />,
    children: phraseMenu,
  },
]

export const tabMenu = [
  {
    label: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
  { label: "Verbs", path: "/verbs", icon: <VerbIcon /> },
  {
    label: "Vocab",
    path: "/vocabulary",
    icon: <VocabIcon />,
    children: vocabMenu,
  },
  {
    label: "Phrases",
    path: "/phrasebook",
    icon: <PhraseIcon />,
    children: phraseMenu,
  },
  {
    label: "Search",
    path: "/search",
    icon: <SearchIcon />,
  },
]

export const aboutMenu = [
  { label: "About", path: "/about" },
  { label: "Contribute", path: "/contribute" },
  { label: "Contact", path: "/contact" },
  { label: "Privacy Policy", path: "/privacy-policy" },
  { label: "Cookie Policy", path: "/cookie-policy" },
]

export const resourceMenu = [
  { label: "Verbs", path: "/verbs" },
  { label: "Vocabulary", path: "/vocabulary" },
  { label: "Phrasebook", path: "/phrasebook" },
]

export const quickLinks = [
  {
    path: "/vocabulary",
    title: "Vocabulary",
    icon: <VocabIcon />,
    color: "#3c82d8",
    description:
      "Learn hundreds of new words with flashcards and pronunciation tools",
  },
  {
    path: "/verbs",
    title: "Conjugations",
    icon: <VerbIcon />,
    color: "#e73747",
    description: `Conjugate the most commonly used ${process.env.GATSBY_LANGUAGE} verbs`,
  },
  {
    path: "/phrasebook",
    title: "Phrasebook",
    icon: <PhraseIcon />,
    color: "#20b60e",
    description:
      "Learn hundreds of new words with flashcards and pronunciation tools",
  },
]
