import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { animated as a, useTransition } from "react-spring"

import { useFlashCards } from "../../context/FlashContext"
import language from "../../config/language"
import Card from "./Card"
import Controls from "./Controls"
import MenuBar from "./MenuBar"
import Shortcuts from "./Shortcuts"

const Flashcard = () => {
  const { cards, flashCardView, toggleFlashCards } = useFlashCards()

  let localCards = cards
  const [cardIndex, changeCard] = useState(0)
  const [flipped, set] = useState(false)
  const [audio, setAudio] = useState(false)
  const [keyStroke, setKeyStroke] = useState(true)
  const [cardEffect, setCardEffect] = useState(null)

  const totalCards = localCards.length

  useEffect(() => {
    if (flashCardView) {
      window.addEventListener(`keydown`, handleKeyDown)
    }
    return () => window.removeEventListener(`keydown`, handleKeyDown)
  })

  function handleEffect(type) {
    setCardEffect(type)
    setTimeout(() => {
      setCardEffect(null)
    }, 250)
  }

  function updateCard(direction) {
    if (direction === "next") {
      handleEffect("next")
      return changeCard(cardIndex === totalCards - 1 ? 0 : cardIndex + 1)
    }
    handleEffect("prev")
    return changeCard(cardIndex === 0 ? totalCards - 1 : cardIndex - 1)
  }

  function shuffle() {
    handleEffect("shuffle")
    localCards = cards.sort(() => Math.random() - 0.5)
    changeCard(cardIndex === totalCards - 1 ? 0 : cardIndex + 1)
  }

  function flipCard() {
    set(flipped => !flipped)
  }

  function listen(type) {
    return type ? setAudio(true) : setAudio(false)
  }

  function handleKeyDown(e) {
    setKeyStroke(false)
    switch (e.keyCode) {
      case 27: // esc
        return toggleFlashCards()
      case 32: // space
      case 38: // up
      case 40: // down
        e.preventDefault()
        return flipCard()
      case 37: // left
        return updateCard("prev")
      case 39: // right
        return updateCard("next")
      case 83: // s
        return shuffle()
      case 76: // l
        return listen(true)
      default:
        return
    }
  }

  const transitions = useTransition(flashCardView, null, {
    from: { opacity: 0, visibility: "hidden" },
    enter: { opacity: 1, visibility: "visible" },
    leave: { opacity: 0, visibility: "hidden" },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <FCView key={key} style={props}>
              <MenuBar shuffle={shuffle} toggle={toggleFlashCards} />
              <Controls update={updateCard} effect={cardEffect} />
              <Card
                language={language}
                effect={cardEffect}
                flipped={flipped}
                setFlip={flipCard}
                data={localCards[cardIndex]}
              />
              <Shortcuts
                hasAudio={language.audio}
                active={keyStroke}
                setActive={setKeyStroke}
                audio={audio}
                setAudio={setAudio}
                language={language.id}
                string={
                  language.special
                    ? localCards[cardIndex].translation.characters
                    : localCards[cardIndex].translation.value
                    ? localCards[cardIndex].translation.value
                    : localCards[cardIndex].translation.masculine
                }
              />
            </FCView>
          )
      )}
    </>
  )
}

const FCView = styled(a.div)`
  background-color: rgba(0, 0, 0, 0.92);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  will-change: opacity;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
`

export default Flashcard
