/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { useState, useRef } from "react"

import Modal from "./Modal"
import SGGrid from "./SGGrid"
import { AppIcon, CaretIcon } from "../assets/icons"

export default () => {
  const buttonRef = useRef(null)
  const [show, set] = useState(false)

  return (
    <div sx={{ position: "relative", display: ["none", "block"] }}>
      <button
        onClick={() => set(!show)}
        ref={buttonRef}
        sx={{
          display: ["none", "block"],
          border: "none",
          background: "none",
          mx: [10],
          outline: "none",
          cursor: "pointer",
        }}
      >
        <AppIcon sx={{ height: 24, fill: "#fff" }} />
      </button>
      <Modal toggle={show} padding="25px" width={250} position={{ top: 37 }}>
        <LanguageLink
          href="https://languageguide.app"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ "&:hover": { color: "primary", svg: { fill: "primary" } } }}
        >
          All Study Guides
          <CaretIcon />
        </LanguageLink>
        <SGGrid fontSize="15px" height={28} width={30} reset={show} />
      </Modal>
    </div>
  )
}

const LanguageLink = styled.a`
  display: flex;
  align-items: center;
  color: #656565;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 5px;
  transition: all ease 0.25s;
  border-bottom: 1px solid #c1c1c1;

  svg {
    fill: #656565;
    margin: 0;
    height: 28px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`
