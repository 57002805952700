/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useState, useEffect } from "react"
import styled from "@emotion/styled"

import { settings } from "../config/language"

export default ({
  columns = 5,
  footer = false,
  fontSize = 18,
  height = 34,
  width = 36,
  reset,
}) => {
  const [current, setCurrent] = useState("Select a language")
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (reset) {
      setCurrent("Select a language")
    }
  }, [reset])

  const updateHover = (active, lang) => {
    if (active) {
      setHover(true)
      setCurrent(lang)
    } else {
      setHover(false)
    }
  }

  return (
    <Fragment>
      <HoverLanguage
        sx={{
          display: ["none", "block"],
          bg: footer ? "rgba(255, 255, 255, 0.1)" : "#f5f5f5",
          color: footer ? "#fff" : "#8c8c8c",
          borderColor: footer ? "rgba(255, 255, 255, 0.33)" : "#e5e4e4",
        }}
      >
        {current}
      </HoverLanguage>
      <Grid
        sx={{
          gridTemplateColumns: [
            "repeat(auto-fill, minmax(40px, 1fr))",
            `repeat(${columns}, 1fr)`,
          ],
        }}
      >
        {settings.map(l => (
          <LanguageLink
            href={l.url}
            onMouseEnter={() => updateHover(true, l.id)}
            onMouseLeave={() => updateHover(false)}
            target="_blank"
            rel="noopener noreferrer"
            key={l.id}
            sx={{
              borderColor: footer
                ? " rgba(255, 255, 255, 0.1)"
                : "rgba(255, 255, 255, 0.26)",
              background: l.color_primary,
              height,
              width,
              fontSize,
              "&:hover": {
                color: "#fff",
                background: l.color_primary,
                borderColor: "#fff",
              },
            }}
          >
            {l.code}
          </LanguageLink>
        ))}
      </Grid>
    </Fragment>
  )
}

const HoverLanguage = styled.div`
  border: 1px solid;
  padding: 5px 10px;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  margin-top: 20px;
`

const LanguageLink = styled.a`
  color: #fff;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all ease 0.3s;
  letter-spacing: 0.5px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`
