import React, { useState, useContext, useMemo } from "react"

const EditContext = React.createContext()

const EditContextProvider = ({ children }) => {
  const [state, setState] = useState({
    editMode: false,
    editModal: false,
    modalType: "",
    postType: "",
    data: {},
  })

  const value = useMemo(() => {
    return { state, setState }
  }, [state])

  return <EditContext.Provider value={value}>{children}</EditContext.Provider>
}

// Usage Hooks

function useEditMode() {
  const { state, setState } = useContext(EditContext)
  const editMode = state.editMode
  const editModal = state.editModal
  const editData = state.data
  const modalType = state.modalType
  const postType = state.postType

  function toggleEditMode() {
    setState(state => ({
      ...state,
      editMode: !state.editMode,
    }))
  }

  function toggleEditModal(modalType, postType, base, translation) {
    if (modalType === undefined) {
      setState(state => ({
        ...state,
        editModal: !state.editModal,
      }))
    }

    if (modalType === "new") {
      setState(state => ({
        ...state,
        editModal: !state.editModal,
        modalType,
        postType,
      }))
    }

    if (modalType === "edit") {
      setState(state => ({
        ...state,
        editModal: !state.editModal,
        data: { base, translation },
        modalType,
        postType,
      }))
    }
  }

  return {
    editMode,
    toggleEditMode,
    editModal,
    editData,
    toggleEditModal,
    modalType,
    postType,
  }
}

export { EditContextProvider, useEditMode }
