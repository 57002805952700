/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect, memo } from "react"
import styled from "@emotion/styled"

import Adblock from "./Adblock"
import Adsense from "./Adsense"

const AdBox = ({ type, gif, sticky }) => {
  // const [adblocker, detect] = useState(false)

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     process.env.NODE_ENV === "development" ||
  //     document.querySelector(".adsbygoogle iframe") === null
  //       ? detect(true)
  //       : detect(false)
  //   }, 100)

  //   return () => clearTimeout(timer)
  // }, [])

  // return adblocker ? (
  //   !gif ? (
  //     <AdWrapper
  //       sx={{
  //         position: sticky ? ["relative", "sticky"] : "relative",
  //         m: sticky ? ["0 auto 40px", "50px auto 40px"] : "0 auto 40px",
  //         top: sticky ? 30 : "auto",
  //       }}
  //     >
  //       <h5>Using an Adblocker?</h5>
  //       <div className="ad-space">
  //         <Adblock type={type} />
  //       </div>
  //     </AdWrapper>
  //   ) : null
  // ) : (
  return (
    <AdWrapper
      sx={{
        position: sticky ? ["relative", "sticky"] : "relative",
        m: sticky ? ["0 auto 40px", "50px auto 40px"] : "0 auto 40px",
        top: 30,
      }}
    >
      <h5>Advertisement</h5>
      <div className="ad-space">
        <Adsense type={type} />
      </div>
    </AdWrapper>
  )
}

const AdWrapper = styled.div`
  width: max-content;
  margin: 0 auto 40px;

  h5 {
    display: inline-block;
    background: #f7f7f7;
    padding: 5px 20px;
    letter-spacing: 0.75px;
    font-size: 16px;
    border: 1px solid gainsboro;
    border-bottom: none;
    color: #9e9e9e;
  }

  .ad-space {
    // overflow: hidden;
    border: 1px solid gainsboro;
    position: relative;
  }
`

export default memo(AdBox)
