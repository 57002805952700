import React, { useState, useContext, useMemo } from "react"
import _ from "lodash"

const FlashContext = React.createContext()

const FlashContextProvider = ({ children }) => {
  const [state, setState] = useState({
    flashCardView: false,
    flashCards: [],
  })

  const value = useMemo(() => {
    return { state, setState }
  }, [state])

  return <FlashContext.Provider value={value}>{children}</FlashContext.Provider>
}

// Usage Hooks

function useFlashCards() {
  const { state, setState } = useContext(FlashContext)
  const cards = state.flashCards
  const flashCardView = state.flashCardView

  function updateCards(data) {
    setState(state => ({
      ...state,
      flashCards: _.flatten(data.map(obj => obj.rows)),
    }))
  }

  function toggleFlashCards() {
    setState(state => ({
      ...state,
      flashCardView: !state.flashCardView,
    }))
  }

  return { cards, flashCardView, toggleFlashCards, updateCards }
}

export { FlashContextProvider, useFlashCards }
