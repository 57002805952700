// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-verb-index-js": () => import("/opt/build/repo/src/components/Verb/index.js" /* webpackChunkName: "component---src-components-verb-index-js" */),
  "component---src-components-phrase-index-js": () => import("/opt/build/repo/src/components/Phrase/index.js" /* webpackChunkName: "component---src-components-phrase-index-js" */),
  "component---src-components-vocab-index-js": () => import("/opt/build/repo/src/components/Vocab/index.js" /* webpackChunkName: "component---src-components-vocab-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contribute-js": () => import("/opt/build/repo/src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-cookie-policy-js": () => import("/opt/build/repo/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-phrasebook-js": () => import("/opt/build/repo/src/pages/phrasebook.js" /* webpackChunkName: "component---src-pages-phrasebook-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-service-js": () => import("/opt/build/repo/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-verbs-js": () => import("/opt/build/repo/src/pages/verbs.js" /* webpackChunkName: "component---src-pages-verbs-js" */),
  "component---src-pages-vocabulary-js": () => import("/opt/build/repo/src/pages/vocabulary.js" /* webpackChunkName: "component---src-pages-vocabulary-js" */)
}

