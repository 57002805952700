// Add image arrays w/ links and authors here
const language = process.env.GATSBY_LANGUAGE

const photoData = [
  {
    id: "Arabic",
    photos: [{ name: "sunset.jpg", author: "sgabriel" }],
  },
  {
    id: "French",
    photos: [
      { name: "Zxs4tMZzdS8", author: "tom32i" }, // French Alps
      { name: "hyZ8ln856Ag", author: "yokeboy" }, // Louvre
      { name: "77pIt7gwVTA", author: "samuelzeller" }, // Flag on boat
      { name: "Fw_2kaQZc90", author: "lepipotron" }, // Paris Revolution
      { name: "suRvdiwP9Pk", author: "peterlaster" }, // Gargoyle
      { name: "Ap8DuvV7fL4", author: "vuitor" }, // Eiffel Tower from Seine
    ],
  },
]

export default photoData.find(({ id }) => id === language).photos
