export default {
  policy: {
    section: {
      borderBottom: "1px solid",
      borderColor: "border",
      py: [20, 40],
    },
    "section:last-child": {
      border: "none",
    },
    a: {
      color: "primary",
    },
    h3: {
      mb: 20,
      fontSize: "1.6em",
    },
    p: {
      mb: 20,
    },
    ul: {
      pl: 20,
      ml: 20,
      listStyle: "initial",
    },
    li: {
      pb: 20,
    },
    div: {
      border: "1px solid #dcdcdc",
      bg: "#fbfbfb",
      p: "20px 30px",
      my: 40,
    },
    h5: {
      fontFamily: "heading",
      mt: 40,
      mb: 15,
      fontWeight: 600,
      fontSize: "1.1em",
    },
    h4: {
      fontFamily: "heading",
      borderBottom: "1px solid",
      borderColor: "border",
      mb: 20,
      pb: "10px",
      fontSize: "1.5em",
      fontWeight: 500,
    },
    ".updated": {
      display: "inline-flex",
      border: "1px solid",
      borderColor: "border",
      color: "#757575",
      p: 20,
      mt: 30,
      mb: 0,
      fontFamily: "heading",
    },
    strong: {
      fontWeight: 700,
    },
  },
}
