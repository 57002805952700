/** @jsx jsx */
import { jsx } from "theme-ui"

import { ReactComponent as UnsplashIcon } from "../../assets/icons/unsplash.svg"
import unsplash from "../../config/unsplash"

export default ({ image }) => {
  const imageMeta = unsplash.find(({ title }) => title === image.name)

  return (
    <a
      href={`https://unsplash.com/@${imageMeta.author}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: "flex",
        alignItems: "center",
        color: "#fff",
        fontFamily: "heading",
        textDecoration: "none",
        fontSize: 20,
      }}
    >
      <UnsplashIcon />
      {imageMeta.author}
    </a>
  )
}
