/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { animated as a, useTransition } from "react-spring"
import styled from "@emotion/styled"

import { API_subscribe } from "../../utils/api"
import { validateEmail } from "../../utils/helper"

const Section = styled(a.div)`
  max-width: 520px;
  width: 100%;
  margin-top: -2%;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  z-index: 1;
`

export default () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const transitions = useTransition(success, null, {
    from: {
      position: "absolute",
      opacity: 0,
      transform: "translate3d(-50%,-52%,0)",
    },
    enter: { opacity: 1, transform: "translate3d(-50%,-50%,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,-52%,0)" },
  })

  const onSubmit = e => {
    e.preventDefault()
    if (validateEmail(email)) {
      setError("")
      API_subscribe(email).then(res => {
        return res.code === 200 ? setSuccess(true) : setError(res.message)
      })
    } else {
      return setError("Please enter a valid email address.")
    }
  }

  return transitions.map(({ item, key, props }) =>
    item ? (
      <Section key={key} style={props} sx={{ bg: "#fff", borderRadius: 5 }}>
        <div sx={{ p: 20 }}>
          <h3
            sx={{
              fontSize: [28, 35],
              mb: 20,
              color: "primary",
            }}
          >
            You're in the loop
          </h3>
          <p sx={{ px: 20, mb: 20, fontFamily: "heading", fontSize: [16, 18] }}>
            Thanks for your interest! <br />
            {`We're very excited for ${process.env.GATSBY_LANGUAGE} too. We will send you an email as soon as it's available.`}
          </p>
        </div>
      </Section>
    ) : (
      <Section key={key} style={props}>
        <h2
          sx={{
            fontSize: [36, 55],
            color: "#fff",
            mb: 36,
            letterSpacing: "2px",
            textShadow: "1px 1px 1px rgba(0, 0, 0, 0.07)",
          }}
        >
          Coming Soon
        </h2>
        <div
          sx={{
            display: "flex",
            mx: 20,
            position: "relative",
            boxShadow: "1px 4px 10px rgba(0,0,0,.15)",
            input: {
              height: 60,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              flex: 1,
              border: "none",
              px: 20,
              fontSize: ["16px", "18px"],
              fontFamily: "heading",
            },
            button: {
              cursor: "pointer",
              color: "#fff",
              bg: "#484848",
              border: "none",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              px: [15, 30],
              fontWeight: 500,
              fontSize: ["16px", "18px"],
              fontFamily: "heading",
            },
          }}
        >
          <input
            placeholder="Enter your email address"
            onChange={e => setEmail(e.target.value)}
          ></input>
          <button onClick={onSubmit}>Notify Me</button>
          {error !== "" ? (
            <div
              sx={{
                position: "absolute",
                bottom: -60,
                left: 0,
                right: 0,
                bg: "#00000080",
                color: "#fff",
                p: 10,
                borderRadius: 2,
              }}
            >
              {error}
            </div>
          ) : null}
        </div>
      </Section>
    )
  )
}
