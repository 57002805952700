import axios from "axios"

export const API_subscribe = async email => {
  try {
    const res = await axios.post("/.netlify/functions/subscribe", {
      email,
      language: process.env.GATSBY_LANGUAGE,
      date: new Date().toLocaleDateString(),
    })
    return { code: res.status, message: "Success!" }
  } catch (e) {
    return {
      code: e.status,
      message: "Whoops, that didn't work. Please try again.",
    }
  }
}

export const API_edit = async props => {
  try {
    const res = await axios.post("/.netlify/functions/edit", {
      email: props.email,
      term: props.term,
      translation: props.translation,
      reason: props.reason,
      path: props.path,
      postType: props.postType,
      suggestion: props.suggestion,
      language: process.env.GATSBY_LANGUAGE,
      date: new Date().toLocaleDateString(),
    })
    return { code: res.status, message: "Success!" }
  } catch (e) {
    return {
      code: e.status,
      message: "That didn't work, please try again.",
    }
  }
}

export const API_new = async props => {
  try {
    const res = await axios.post("/.netlify/functions/new", {
      email: props.email,
      term: props.term,
      translation: props.translation,
      gender: props.gender,
      postType: props.postType,
      path: props.path,
      language: process.env.GATSBY_LANGUAGE,
      date: new Date().toLocaleDateString(),
    })
    return { code: res.status, message: "Success!" }
  } catch (e) {
    return {
      code: e.status,
      message: "That didn't work, please try again.",
    }
  }
}
