const language = process.env.GATSBY_LANGUAGE

export const settings = [
  {
    id: "Arabic",
    code: "ar",
    dev: true,
    special: true,
    audio: true,
    color_primary: "#0a9531",
    color_secondary: "#0a9531",
    url: "https://arabicstudyguide.com",
  },
  {
    id: "Chinese",
    code: "zh",
    dev: false,
    special: true,
    audio: true,
    color_primary: "#a90b0b",
    color_secondary: "#a90b0b",
    url: "https://chinesestudyguide.com",
    formats: [
      { key: "all", label: "Pinyin + Characters" },
      { key: "romanize", label: "Pinyin Only" },
      { key: "characters", label: "Characters Only" },
    ],
  },
  {
    id: "Danish",
    code: "da",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#a70808",
    color_secondary: "#a70808",
    url: "https://danishstudyguide.com",
  },
  {
    id: "Dutch",
    code: "nl",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#dc550e",
    color_secondary: "#dc550e",
    url: "https://dutchstudyguide.com",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "French",
    code: "fr",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#2184dc",
    color_secondary: "#063670",
    color_home_header: "rgba(15, 100, 214, 0.2)",
    color_highlight: ["#f5faff", "#dbebfb"],
    url: "https://frenchstudyguide.com",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "German",
    code: "de",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#333333",
    color_secondary: "#333333",
    url: "https://germanstudyguide.co",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "Greek",
    code: "el",
    dev: true,
    special: true,
    audio: true,
    color_primary: "#0d5eaf",
    color_secondary: "#0d5eaf",
    url: "https://greekstudyguide.com",
  },
  {
    id: "Hebrew",
    code: "he",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#156fc5",
    color_secondary: "#156fc5",
    url: "https://hebrewstudyguide.com",
  },
  {
    id: "Hindi",
    code: "hi",
    dev: true,
    special: true,
    audio: true,
    color_primary: "#128806",
    color_secondary: "#128806",
    url: "https://hindistudyguide.com",
  },
  {
    id: "Italian",
    code: "it",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#28a744",
    color_secondary: "#28a744",
    url: "https://italianstudyguide.com",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "Japanese",
    code: "ja",
    dev: false,
    special: true,
    audio: true,
    color_primary: "#980303",
    color_secondary: "#980303",
    url: "https://japanesestudyguide.com",
    formats: [
      { key: "all", label: "Kana + Romaji" },
      { key: "romanize", label: "Romaji Only" },
      { key: "characters", label: "Kana Only" },
    ],
  },
  {
    id: "Korean",
    code: "ko",
    dev: true,
    special: true,
    audio: true,
    color_primary: "#9a0539",
    color_secondary: "#9a0539",
    url: "https://koreanstudyguide.com",
  },
  {
    id: "Norwegian",
    code: "no",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#022765",
    color_secondary: "#022765",
    url: "https://norwegianstudyguide.com",
  },
  {
    id: "Polish",
    code: "pl",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#ad0000",
    color_secondary: "#ad0000",
    url: "https://polishstudyguide.com",
  },
  {
    id: "Portuguese",
    code: "pt",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#056501",
    color_secondary: "#056501",
    url: "https://portuguesestudyguide.com",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "Russian",
    code: "ru",
    dev: true,
    special: true,
    audio: true,
    color_primary: "#cf2a1c",
    color_secondary: "#cf2a1c",
    url: "https://russianstudyguide.com",
  },
  {
    id: "Spanish",
    code: "es",
    dev: false,
    special: false,
    audio: true,
    color_primary: "#0db54a",
    color_secondary: "#0db54a",
    url: "https://spanishstudyguide.com",
    formats: [
      { key: "definite", label: "Definite Article" },
      { key: "indefinite", label: "Indefinite Article" },
      { key: "hide", label: "Hide Article" },
    ],
  },
  {
    id: "Swahili",
    code: "sw",
    dev: true,
    special: false,
    audio: false,
    color_primary: "#046600",
    color_secondary: "#046600",
    url: "https://swahilistudyguide.com",
  },
  {
    id: "Swedish",
    code: "sv",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#0f6baa",
    color_secondary: "#0f6baa",
    url: "https://swedishstudyguide.com",
  },
  {
    id: "Turkish",
    code: "tr",
    dev: true,
    special: false,
    audio: true,
    color_primary: "#cc1f26",
    color_secondary: "#cc1f26",
    url: "https://turkishstudyguide.com",
  },
  {
    id: "Ukrainian",
    code: "uk",
    dev: true,
    special: true,
    audio: false,
    color_primary: "#075bbc",
    color_secondary: "#075bbc",
    url: "https://ukrainianstudyguide.com",
  },
  {
    id: "Vietnamese",
    code: "vi",
    dev: true,
    special: false,
    audio: false,
    color_primary: "#a21111",
    color_secondary: "#a21111",
    url: "https://vietnamesestudyguide.com",
  },
]

export default settings.find(({ id }) => id === language)
