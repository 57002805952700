/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { Location } from "@reach/router"

import EditModal from "./EditModal"
import StatusBar from "./StatusBar"
import { useEditMode } from "../../context/EditContext"

export default () => {
  const {
    editMode,
    editModal,
    editData,
    toggleEditMode,
    toggleEditModal,
    modalType,
    postType,
  } = useEditMode()

  return (
    <Location>
      {({ location }) => (
        <Fragment>
          <EditModal
            editModal={editModal}
            data={editData}
            toggleModal={toggleEditModal}
            path={location.pathname}
            type={modalType}
            postType={postType}
          />
          <StatusBar editMode={editMode} toggleEditMode={toggleEditMode} />
        </Fragment>
      )}
    </Location>
  )
}
