/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Fragment } from "react"

import { ReactComponent as CheckMark } from "../../assets/icons/success.svg"

export default () => {
  return (
    <Fragment>
      <ImageWrapper>
        <CheckMark />
      </ImageWrapper>
      <h3 sx={{ fontSize: [21, 28], border: "none" }}>Thank you!</h3>
      <TextWrapper>
        <p>
          Every edit makes this guide a better learning resource, so we really
          appreciate your contribution.
        </p>
        <div className="notice">
          As a special thanks, we'll invite you to be one of our first users as
          soon as the feature is ready.
        </div>
      </TextWrapper>
    </Fragment>
  )
}

const ImageWrapper = styled.div`
  padding-top: 30px;

  .checkmark {
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
`

const TextWrapper = styled.div`
  margin: 0 30px;

  p {
    text-align: center;
    margin-bottom: 30px;
  }

  .notice {
    border: 1px solid gainsboro;
    border-radius: 5px;
    background: #fbfbfb;
    text-align: center;
    padding: 20px 20px 15px;
    margin-bottom: 20px;
    font-family: Avenir Next;

    div {
      border-top: 1px solid gainsboro;
      padding-top: 15px;
      margin-top: 20px;
    }
  }
`
