/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import {
  Footer,
  FooterWidgets,
  TabBar,
  useMeasurements,
} from "gatsby-theme-elements"
import { Link } from "gatsby"

import { aboutMenu, resourceMenu, tabMenu } from "./menus"
import SGGrid from "../SGGrid"

export default () => {
  const metrics = useMeasurements()

  return (
    <Fragment>
      <Footer sx={{ pt: "4em", fontFamily: "heading" }}>
        <FooterWidgets
          sx={{
            px: ["20px", 0],
            width: "auto",
            maxWidth: "1020px",
            h3: {
              color: "#fff",
              borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
              fontSize: "23px",
              mb: "25px",
              pb: "5px",
            },
            ul: {
              fontSize: "18px",
              lineHeight: 2.3,
              a: {
                textDecoration: "none",
                color: "#fff",
              },
            },
          }}
        >
          <div>
            <h3>About</h3>
            <ul>
              {aboutMenu.map(({ label, path }) => (
                <li key={path}>
                  <Link to={path}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Language Resources</h3>
            <ul>
              {resourceMenu.map(({ label, path }) => (
                <li key={path}>
                  <Link to={path}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>All Study Guides</h3>
            <SGGrid footer />
          </div>
        </FooterWidgets>
        <div
          sx={{
            color: "#fff",
            mt: "6em",
            pt: "35px",
            pb: ["90px", "75px"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          © 2019 Language Guides
        </div>
      </Footer>
      {metrics.viewportX < 780 ? (
        // TODO - custom children with a search button at the end to toggle search view
        <TabBar
          menuItems={tabMenu}
          sx={{
            fontFamily: "heading",
            svg: { fill: "primary" },
          }}
        />
      ) : null}
    </Fragment>
  )
}
