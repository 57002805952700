export { ReactComponent as AccountIcon } from "./account.svg"
export { ReactComponent as AppIcon } from "./apps.svg"
export { ReactComponent as VerbIcon } from "./verb.svg"
export { ReactComponent as PhraseIcon } from "./phrase.svg"
export { ReactComponent as EditIcon } from "./edit.svg"
export { ReactComponent as FlashCardIcon } from "./flashcard.svg"
export { ReactComponent as AllVerbsIcon } from "./all-verbs.svg"
export { ReactComponent as PopularVerbsIcon } from "./popular-verbs.svg"
export { ReactComponent as SchoolIcon } from "./school.svg"
export { ReactComponent as HomeIcon } from "./home.svg"
export { ReactComponent as VocabIcon } from "./vocab.svg"
export { ReactComponent as PlaneIcon } from "./plane.svg"
export { ReactComponent as CloseIcon } from "./close.svg"
export { ReactComponent as ShuffleIcon } from "./shuffle.svg"
export { ReactComponent as KeyboardIcon } from "./keyboard.svg"
export { ReactComponent as SearchIcon } from "./search.svg"
export { ReactComponent as ArrowIcon } from "./arrow-right.svg"
export { ReactComponent as TrendingIcon } from "./trending.svg"
export { ReactComponent as CaretIcon } from "./caret-right.svg"
export { ReactComponent as ListIcon } from "./list.svg"
export { ReactComponent as NextIcon } from "./next.svg"

export { default as CommunityIcon } from "./community.svg"
export { default as DirectionIcon } from "./DirectionIcon"
export { default as AlgoliaIcon } from "./algolia.svg"
export { default as AlgoliaDarkIcon } from "./algolia-dark.svg"
