/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, Fragment } from "react"
import styled from "@emotion/styled"
import { Header, Logo, NavMenu, HeaderWidgets } from "gatsby-theme-elements"
import { Link } from "gatsby"

import { SearchIcon, AccountIcon, CaretIcon } from "../../assets/icons"
import Modal from "../Modal"
import SGPicker from "../SGPicker"

const MenuItem = ({ item }) => {
  const [hover, setHover] = useState(false)

  function renderDropdown(type, path) {
    return (
      <Modal toggle={hover} padding="25px" width={280} position={{ top: 57 }}>
        <DropHeading
          to={path}
          sx={{ "&:hover": { color: "primary", svg: { fill: "primary" } } }}
        >
          View All {type}
          <CaretIcon />
        </DropHeading>
        <ul sx={{ columns: 2, columnGap: 30 }}>
          {item.children.map(({ label, path }) => (
            <li
              key={path}
              sx={{
                mb: "10px",
                a: {
                  color: "#000",
                  transition: "all ease .25s",
                  "&:hover": { color: "primary" },
                },
              }}
            >
              <Link to={path}>{label}</Link>
            </li>
          ))}
        </ul>
      </Modal>
    )
  }

  return (
    <li
      onMouseEnter={() => (item.children ? setHover(true) : null)}
      onMouseLeave={() => (item.children ? setHover(false) : null)}
      sx={{ transition: "all ease .3s", position: "relative" }}
    >
      <Link to={item.path}>
        {item.icon}
        <span
          sx={{
            textShadow: "1px 1px 1px rgba(0, 0, 0, 0.15)",
            mr: item.children ? "-7px" : null,
          }}
        >
          {item.label} {item.children ? <Fragment>&#9662;</Fragment> : null}
        </span>
      </Link>
      {item.children ? renderDropdown(item.label, item.path) : null}
    </li>
  )
}

export default ({ language, navMenu }) => (
  <Header
    justify="space-between"
    border="none"
    sx={{ alignItems: "stretch", p: 0 }}
  >
    <Logo sx={{ color: "#fff", fontWeight: 500 }}>
      <LogoMark sx={{ mr: [15, 20], width: [55, 70], fontSize: [23, 30] }}>
        {language.code}
      </LogoMark>
      <WordMark
        sx={{ fontSize: [24, 31], py: "10px" }}
      >{`${language.id} Study Guide`}</WordMark>
    </Logo>

    <NavMenu flex justify="flex-end">
      <ul
        sx={{
          display: "flex",
          borderRight: "1px solid rgba(255, 255, 255, 0.2)",
          svg: {
            height: "20px",
            fill: "#fff",
            mb: "7px",
          },
          "> li:hover": {
            bg: "rgba(255,255,255,0.1)",
          },
          "> li > a": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            p: "0 30px",
            textTransform: "uppercase",
            color: "#fff",
            fontWeight: 500,
            fontSize: ".63em",
            letterSpacing: "1.2px",
            borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {navMenu.map(item => (
          <MenuItem key={item.path} item={item} />
        ))}
      </ul>
    </NavMenu>
    <HeaderWidgets sx={{ px: [15, 20] }}>
      <SGPicker />
      <Link to="/login">
        <AccountIcon
          sx={{
            fill: "#fff",
            height: 23,
            mx: [0, 20],
            display: ["none", "block"],
          }}
        />
      </Link>
      <Link to="/search" sx={{ display: "flex" }}>
        <SearchIcon sx={{ fill: "#fff", height: 23, mx: [0, 20] }} />
      </Link>
    </HeaderWidgets>
  </Header>
)

const LogoMark = styled.div`
  border-right: 3px solid rgba(255, 255, 255, 0.15);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  height: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all ease 0.4s;
`

const WordMark = styled.div`
  letterspacing: 0.33;
`

const DropHeading = styled(Link)`
  display: flex;
  align-items: center;
  color: #656565;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 5px;
  transition: all ease 0.25s;
  border-bottom: 1px solid #c1c1c1;

  svg {
    fill: #656565;
    margin: 0;
    height: 28px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`
