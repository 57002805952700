/** @jsx jsx */
import { jsx } from "theme-ui"

// import styled from "@emotion/styled"

import { speak } from "./speech"
import AudioIcon from "./AudioIcon"

const AudioButton = ({ string, language, audio = false, setAudio, index }) => {
  // Helper for prop drilling outside of onClick
  if (audio) {
    speak(string, language)
    setAudio(false)
  }

  const handleClick = e => {
    speak(string, language)
  }

  return (
    <button
      className="edit-theme"
      sx={{
        p: 0,
        m: 0,
        ml: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 30,
        border: "none",
        background: "none",
        outline: "none",
        cursor: "pointer",
        transition: "all ease .3s",
        "svg, img": {
          height: 20,
          m: 0,
          transition: "transform ease 0.3s",
        },
        "&:hover, &:active, &:focus": {
          "svg, img": {
            transform: "scale(1.2)",
          },
        },
      }}
      onClick={handleClick}
    >
      <AudioIcon use={index > 0 ? true : false} />
    </button>
  )
}

export default AudioButton
