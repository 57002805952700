/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect, Fragment } from "react"
import { animated as a, useTransition } from "react-spring"
import styled from "@emotion/styled"

import { API_edit, API_new } from "../../utils/api"
import { validateEmail } from "../../utils/helper"
import SuccessMessage from "./Success"

const radioOptions = [
  { label: "Fix an Error", value: "Error", title: "Revised Translation" },
  {
    label: "Alternate Translation",
    value: "Alternate",
    title: "Alternate Translation",
  },
]

const genderOptions = ["N/A", "Masculine", "Feminine"]

function getTranslation(trans) {
  if (trans.value !== null) {
    return trans.value
  }
  if (trans.hybrid !== null) {
    return trans.hybrid
  }
  if (trans.characters !== null) {
    return trans.characters
  }
}

export default ({ editModal, data, toggleModal, path, type, postType }) => {
  const [radio, setRadio] = useState("Error")
  const [text, setText] = useState("")
  const [gender, setGender] = useState("N/A")
  const [trans, setTrans] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setError("")
    setSuccess(false)
  }, [editModal])

  const onSubmit = e => {
    e.preventDefault()
    const params =
      type === "edit"
        ? {
            term: data.base,
            translation: getTranslation(data.translation),
            reason: radio,
            suggestion: text,
            postType,
            email,
            path,
          }
        : {
            term: text,
            gender,
            postType,
            translation: trans,
            email,
            path,
          }

    // Handle Edit Modal Submit

    if (type === "edit") {
      if (text.length < 1) {
        return setError("Please complete all fields before submitting.")
      }

      if (!validateEmail(email)) {
        return setError("Please enter a valid email address.")
      }

      setError("")
      API_edit(params).then(res => {
        return res.code === 200 ? setSuccess(true) : setError(res.message)
      })
    }

    // Handle New Word Submit

    if (type === "new") {
      if (text.length < 1 && trans.length < 1) {
        return setError("Please complete all fields before submitting.")
      }

      if (!validateEmail(email)) {
        return setError("Please enter a valid email address.")
      }

      setError("")
      API_new(params).then(res => {
        return res.code === 200 ? setSuccess(true) : setError(res.message)
      })
    }
  }

  const transitions = useTransition(editModal, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Container key={key} style={props} onClick={() => toggleModal()}>
          <FormBox
            onClick={e => e.stopPropagation()}
            sx={{ minWidth: ["90vw", 420] }}
          >
            {success ? (
              <SuccessMessage />
            ) : (
              <Fragment>
                <h3
                  sx={{
                    fontSize: [21, 28],
                    color: "#e1696a",
                    bg: "#fbfbfb",
                    borderBottom: "1px solid #dcdcdc",
                  }}
                >
                  {type === "edit" ? (
                    "Submit an Edit"
                  ) : (
                    <Fragment>
                      {postType === "vocab" ? "Add a Word" : "Add a Phrase"}
                    </Fragment>
                  )}
                </h3>
                <FormFields>
                  {type === "edit" ? (
                    // Edit Form Fields
                    <Fragment>
                      <FieldWrapper>
                        <Label>
                          {postType === "verb" ? "Verb - Tense" : "English"}
                        </Label>
                        <div className="field" sx={{ bg: "#f7f7f7" }}>
                          {data.base}
                        </div>
                      </FieldWrapper>
                      <FieldWrapper sx={{ mb: 15 }}>
                        <Label sx={{ bg: "edit", position: "absolute" }}>
                          {postType === "verb"
                            ? "Correct Conjugation(s)"
                            : radioOptions.find(({ value }) => value === radio)
                                .title}
                        </Label>
                        <textarea
                          required
                          className="field"
                          rows="1"
                          onChange={e => setText(e.target.value)}
                          placeholder={
                            postType === "verb"
                              ? "Please include any notes or explanations"
                              : getTranslation(data.translation)
                          }
                        />
                      </FieldWrapper>
                      {postType !== "verb" ? (
                        <Fragment>
                          <div sx={{ mb: 10, fontFamily: "heading" }}>
                            Reason
                          </div>
                          <RadioButtons>
                            {radioOptions.map(({ label, value }) => (
                              <div key={value} onClick={() => setRadio(value)}>
                                <input
                                  type="radio"
                                  name={value}
                                  value={value}
                                  checked={value === radio ? true : false}
                                  onChange={e => setRadio(e.target.value)}
                                />
                                <label htmlFor={value}>{label}</label>
                              </div>
                            ))}
                          </RadioButtons>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : (
                    // New Word Form Fields
                    <Fragment>
                      <FieldWrapper>
                        <Label sx={{ bg: "edit" }}>English</Label>
                        <input
                          type="text"
                          className="field"
                          onChange={e => setText(e.target.value)}
                          placeholder={
                            postType === "vocab" ? "New word" : "New phrase"
                          }
                        />
                      </FieldWrapper>
                      <FieldWrapper sx={{ mb: 15 }}>
                        <Label sx={{ bg: "edit", position: "absolute" }}>
                          Translation
                        </Label>
                        <textarea
                          required
                          className="field"
                          rows="1"
                          onChange={e => setTrans(e.target.value)}
                          placeholder={`${process.env.GATSBY_LANGUAGE} translation. You can include notes.`}
                        />
                      </FieldWrapper>
                      {postType === "vocab" ? (
                        <Fragment>
                          <div sx={{ mb: 10, fontFamily: "heading" }}>
                            Gender
                          </div>
                          <RadioButtons>
                            {genderOptions.map(item => (
                              <div key={item} onClick={() => setGender(item)}>
                                <input
                                  type="radio"
                                  name={item}
                                  value={item}
                                  checked={item === gender ? true : false}
                                  onChange={e => setGender(e.target.value)}
                                />
                                <label htmlFor={item}>{item}</label>
                              </div>
                            ))}
                          </RadioButtons>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  )}
                  <div sx={{ mb: 10, fontFamily: "heading" }}>
                    {error.length > 1 ? (
                      <span sx={{ color: "red" }}>{error}</span>
                    ) : (
                      "Email Address"
                    )}
                  </div>
                  <SubmitWrapper>
                    <input
                      type="text"
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Your email"
                    />
                    <button sx={{ bg: "edit" }} onClick={onSubmit}>
                      Submit
                    </button>
                  </SubmitWrapper>
                </FormFields>
              </Fragment>
            )}
          </FormBox>
        </Container>
      )
  )
}

const Container = styled(a.div)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.33);
`

const FormBox = styled.div`
  overflow: hidden;
  padding-bottom: 20px;
  margin: 0 20px;
  background: #fff;
  max-width: 500px;
  max-height: 85vh;
  overflow: scroll;
  box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;

  h3 {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px 0 15px;
  }
`

const FormFields = styled.div`
  margin: 0 30px;
`

const FieldWrapper = styled.div`
  margin: 10px 0 30px;
  position: relative;

  .field {
    padding: 15px 20px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    border-top-left-radius: 0;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
  }

  textarea {
    margin-top: 28px;
  }
`

const Label = styled.div`
  background: #949494;
  padding: 2px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  display: inline-flex;
  font-family: Avenir Next;
  font-size: 16px;
  color: #fff;
`

const RadioButtons = styled.div`
  display: flex;
  border: 1px solid #dcdcdc;
  background: #fbfbfb;
  justify-content: center;
  padding: 15px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
  font-family: Avenir Next;

  input {
    cursor: pointer;
  }

  label {
    margin: 0 20px 0 5px;
    cursor: pointer;
  }
`

const SubmitWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;

  input {
    border: 1px solid #dcdcdc;
    flex: 1;
    margin-right: 15px;
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 2px;
  }

  button {
    border: none;
    border-radius: 2px;
    color: #fff;
    font-family: Avenir Next;
    font-size: 17px;
    padding: 10px 30px;
  }
`
