/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { animated as a, useTransition } from "react-spring"
import styled from "@emotion/styled"

import { CloseIcon } from "../../assets/icons"

export default ({ editMode, toggleEditMode }) => {
  const transitions = useTransition(editMode, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <Fragment>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Status
              key={key}
              style={props}
              sx={{
                fontFamily: "heading",
                bg: "edit",
                height: [56, 66],
                span: {
                  display: ["none", "inline-flex"],
                },
              }}
            >
              <span>Click a row or add a new term</span>
              <CloseButton
                sx={{ fontFamily: "heading" }}
                onClick={() => toggleEditMode()}
              >
                <CloseIcon />
                Close
              </CloseButton>
            </Status>
          )
      )}
    </Fragment>
  )
}

const Status = styled(a.div)`
  z-index: 1000;
  position: absolute;
  padding: 0 30px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 30px;
  color: #fff;
  font-size: 20px;

  svg {
    fill: #fff;
    margin-right: 5px;
    height: 30px;
  }
`
