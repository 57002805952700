import table from "./table"
import policy from "./policy"
import language from "../config/language"

export default {
  initialColorMode: "light",
  colors: {
    text: "#000",
    background: "#fff",
    edit: "#fc7a7a",
    primary: language.color_primary,
    secondary: language.color_secondary,
    accent: language.color_primary,
    highlight: language.color_highlight[0],
    highlight_border: language.color_highlight[1],
    muted: "#f9f9f9",
    border: "#dcdcdc",
    bg_header: language.color_primary,
    bg_footer: language.color_primary,
    modes: {
      lightHeader: {},
    },
  },
  fonts: {
    body: "Noto Sans, Helvetica, serif",
    heading: "Avenir Next, Noto Sans, Helvetica, sans-serif",
    monospace: "Menlo, monospace",
  },
  shadows: {
    header: null,
    tabbar: null,
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64],
  navlink: {
    fontSize: "30px",
  },
  editMode: {
    section: {
      border: ["none", "1px solid #fac3c3"],
    },
  },
  tableSection: {
    mb: [0, 30],
    pb: [0, 10],
    border: ["none", "1px solid #dcdcdc"],
    borderTop: "1px solid #dcdcdc",
    transition: "background-color ease .5s",
  },
  headings: {
    h1: {
      fontSize: ["2em", "3em"],
      m: "0 0 10px",
    },
    h2: {
      color: "primary",
      fontSize: [22, 25],
      bg: "#f9f9f9",
    },
  },
  sideMenu: {
    mb: 30,
    bg: "#fff",
    border: "1px solid #dcdcdc",
    overflow: "hidden",
    boxShadow: "1px 1px 2px rgba(0,0,0,0.03)",
    h2: {
      variant: "headings.h2",
      textAlign: "center",
      py: 10,
      bg: "#f9f9f9",
      borderBottom: "1px solid #dcdcdc",
    },
    ul: {
      columns: 2,
      columnGap: 0,
      columnRule: "1px solid #dcdcdc",
    },
    li: {
      borderBottom: "1px solid #dcdcdc",
      breakInside: "avoid",
    },
    a: {
      display: "block",
      p: "10px",
      textAlign: "center",
      textDecoration: "none",
      fontSize: "15px",
      color: "#737373",
      transition: "all ease .3s",
      "&:hover": {
        bg: "#f9f9f9",
        color: "primary",
      },
    },
  },
  ...policy,
  contentPage: {
    h1: {
      variant: "headings.h1",
    },
    p: {
      fontSize: [16, 20],
      fontFamily: "heading",
      mb: 30,
    },
    h2: {
      mb: "20px",
      color: "primary",
      fontSize: 30,
    },
    section: {
      m: "30px 0",
    },
    a: {
      color: "primary",
    },
  },
  // Styled Components
  styles: {
    ...table,
    h1: {
      fontSize: ["2em", "3em"],
      fontWeight: 500,
      m: "0 0 10px",
    },
    h2: {
      fontSize: "3.1em",
      fontWeight: 500,
      m: "0 0 10px",
    },
    em: {
      fontStyle: "italic",
    },
  },
}
