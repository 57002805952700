/** @jsx jsx */
import { jsx } from "theme-ui"
import { Layout } from "gatsby-theme-elements"
import Helmet from "react-helmet"
import { Fragment } from "react"

import { FlashContextProvider } from "../../context/FlashContext"
import { EditContextProvider } from "../../context/EditContext"

import Header from "./Header"
import Footer from "./Footer"
import PageTemplate from "../Page"
import CookieConsent from "../CookieConsent"
import LandingPage from "../Landing"
import Flashcard from "../FC"
import EditMode from "../EditMode"

import global from "../../gatsby-theme-elements/global"
import language from "../../config/language"
import { navMenu } from "./menus"

const fullWidth = ["/", "/vocabulary", "/phrasebook", "/search", "/login"]

function renderLayout(location, children, pageContext) {
  // Home
  if (fullWidth.includes(location.pathname)) {
    return children
  }
  // Normal Page
  if (pageContext.isCreatedByStatefulCreatePages) {
    return <PageTemplate>{children}</PageTemplate>
  }
  // Post Templates (via gatsby-node.js)
  return (
    <FlashContextProvider>
      <EditContextProvider>
        {children}
        <Flashcard />
        <EditMode />
      </EditContextProvider>
    </FlashContextProvider>
  )
}

export default ({ children, location, pageContext }) => {
  return process.env.DEV_MODE ? (
    <LandingPage />
  ) : (
    <Fragment>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <Layout globalStyle={global}>
        <Header language={language} navMenu={navMenu} />
        {renderLayout(location, children, pageContext)}
        <Footer />
      </Layout>
      <CookieConsent language={language} />
    </Fragment>
  )
}
