/** @jsx jsx */
import { jsx } from "theme-ui"
import { Layout, Header, Logo, HeaderWidgets } from "gatsby-theme-elements"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../Seo"
import SGPicker from "../SGPicker"
import SubscribeForm from "./SubscribeForm"
import Background from "./Background"
import UnsplashBadge from "./UnsplashBadge"
import language from "../../config/language"

export default () => {
  const data = useStaticQuery(
    graphql`
      query imageQuery {
        allFile(filter: { extension: { regex: "/(jpg)/" } }) {
          nodes {
            ...ImageFragment
          }
        }
      }
    `
  )

  const currentImage =
    data.allFile.nodes[Math.floor(Math.random() * data.allFile.nodes.length)]

  return (
    <Layout>
      <SEO title="Coming Soon" />
      <Header
        justify="space-between"
        border="1px solid rgba(255, 255, 255, 0.23)"
        backgroundColor="rgba(255, 255, 255, 0.08)"
        sx={{
          alignItems: "stretch",
          p: 0,
          a: { color: "#fff", textDecoration: "none", alignItems: "center" },
        }}
      >
        <Logo sx={{ variant: "logo", fontSize: [24, 30] }}>
          <span>{language.code}</span>
          {`${language.id} Study Guide`}
        </Logo>

        <HeaderWidgets sx={{ px: [15, 20] }}>
          <SGPicker />
        </HeaderWidgets>
      </Header>
      <Background fluid={data.allFile.nodes[0].childImageSharp.fluid} />
      <SubscribeForm />
      <div
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          p: 25,
          svg: { height: 36, mr: 15 },
        }}
      >
        <UnsplashBadge image={currentImage} />
      </div>
    </Layout>
  )
}
