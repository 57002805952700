import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { CloseIcon, ShuffleIcon } from "../../assets/icons"

const MenuBar = ({ shuffle, toggle }) => (
  <Menu>
    <Shuffle className="naked-btn" onClick={shuffle}>
      <ShuffleIcon />
      Shuffle
    </Shuffle>
    <Close className="naked-btn" onClick={() => toggle(null, false)}>
      <CloseIcon />
    </Close>
  </Menu>
)

const Shuffle = styled.button`
  border: 2px solid rgba(255, 255, 255, 0.2) ;
  border-radius: 2px;
  background: none;
  font-family: "Avenir Next";
  font-size: 20px;
  color: #fff;
  padding: 10px 25px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease .3s;

  svg {
    fill #fff;
    margin-right: 10px;
    height: 30px;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.7);
  }
`

const Close = styled.button`
  margin-left: 20px;
  background: none;
  border: none;
  svg {
    fill: #fff;
    height: 55px;
    width: auto;
    display: flex;
  }
`

const Menu = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  z-index: 100;

  @media (min-width: 750px) {
    padding: 20px 30px;
  }
`

MenuBar.propTypes = {
  shuffle: PropTypes.func,
  toggle: PropTypes.func,
}

MenuBar.defaultProps = {
  shuffle: () => {},
  toggle: () => {},
}

export default MenuBar
