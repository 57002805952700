/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Modal = ({ toggle, position, padding, children, width = "auto" }) => {
  return (
    <Container
      className={toggle ? "active" : ""}
      // className={"active"}
      sx={{
        width,
        p: padding,
        top: position.top,
        bottom: position.bottom,
        right: position.right,
      }}
    >
      {children}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  z-index: 100;
  padding: 25px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.1),
    0 0 15px 0 rgba(37, 44, 97, 0.05), 0 2px 4px 0 rgba(93, 100, 148, 0.1),
    0 -4px 16px 0 rgba(93, 100, 148, 0.025);
  transition: all 0.25s ease-out;
  transform: translate(-50%, 0);
  left: 50%;
  opacity: 0;
  visibility: hidden;

  &.active {
    transform: translate(-50%, 10px);
    opacity: 1;
    visibility: visible;
  }

  :after {
    content: "";
    width: 15px;
    height: 15px;
    background-color: white;
    position: absolute;
    margin: auto;
    top: -7px;
    left: 47.5%;
    transform: rotate(-45deg);
  }
`

Modal.propTypes = {
  toggle: PropTypes.bool,
  position: PropTypes.object,
  padding: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Modal.defaultProps = {
  toggle: null,
  position: {},
  padding: ``,
  classes: ``,
}

export default Modal
