/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { ContentWrapper, Main, Sidebar } from "gatsby-theme-elements"

import AdBox from "./Ad"

const PageTemplate = ({ children }) => {
  return (
    <ContentWrapper layout="content-sidebar" sx={{ p: [0, "60px 20px"] }}>
      <Main>
        <div
          sx={{
            p: ["50px 20px", "0 20px"],
            opacity: 0,
            animation: "fadeInUp .4s ease forwards .2s",
          }}
        >
          {children}
        </div>
      </Main>
      <Sidebar>
        <AdContainer>
          <AdBox type="small" />
          <AdBox type="large" sticky />
        </AdContainer>
      </Sidebar>
    </ContentWrapper>
  )
}

const AdContainer = styled.div`
  // opacity: 0;
  // animation: fadeInUp ease 0.5s forwards 0.6s;
`

export default PageTemplate
