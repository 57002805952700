export default {
  table: {
    m: 0,
    width: "100%",
    td: { p: "10px", fontSize: 17 },
    borderCollapse: "collapse",
    ".characters": {
      fontSize: 23,
    },
    ".romanize": {
      fontSize: "15px",
      color: "#868686",
    },
    ".hide-mobile": {
      display: ["none", "table-cell"],
    },
    ".hide-desktop": {
      display: ["table-cell", "none"],
      overflowWrap: "break-word",
      maxWidth: 140,
    },
    ".table-heading": {
      zIndex: 1,
      boxShadow: ["1px 1px 1px #dcdcdc", "none"],
      fontFamily: "heading",
      color: "primary",
      bg: "#f9f9f9",
      borderBottom: ["none", "1px solid #dcdcdc"],
      p: "12px 20px",
      fontWeight: 500,
      textAlign: "left",
      fontSize: [22, 26],
      position: ["sticky", "relative"],
      top: 0,
    },
    ".heading-one-col": {
      bg: "#f9f9f9",
      fontFamily: "heading",
      color: "primary",
      borderBottom: "1px solid #dcdcdc",
      th: {
        p: "12px 8px",
        fontWeight: 500,
        fontSize: 20,
        textAlign: "left",
      },
      "th:first-of-type": {
        pl: 20,
      },
    },
    tr: {
      transition: "background-color ease .3s",
      td: {
        verticalAlign: "middle",
        transition: "color ease .2s",
      },
      "td:first-of-type": {
        fontWeight: "bold",
        pl: 20,
      },
      "td:last-of-type": {
        pr: 12,
      },
    },
  },
}
